import { defineStore } from 'pinia';
import { ref } from 'vue';

export type ConfirmCallback = (isConfirmed: boolean) => void;

export interface ConfirmProps {
  title: string;
  description?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hideCloseIcon?: boolean;
  confirmButtonOnly?: boolean;
  cancelButtonOnly?: boolean;
  maxWidth?: string;
  zIndex?: number;
}

export interface ConfirmData extends ConfirmProps {
  callback: ConfirmCallback;
}

export const useConfirmStore = defineStore('confirm', () => {
  const isOpen = ref<boolean>(false);
  const confirmData = ref<ConfirmData | null>(null);

  const openConfirm = (data: ConfirmData): void => {
    confirmData.value = data;
    isOpen.value = true;
  };

  const onClose = (isSubmitted: boolean): void => {
    if (confirmData.value) {
      confirmData.value.callback(isSubmitted);
      // Adjust the interval for the animation
      const timerId = setTimeout(() => {
        confirmData.value = null;
        clearTimeout(timerId);
      }, 200);
    }
    isOpen.value = false;
  };

  return { isOpen, confirmData, openConfirm, onClose };
});

export const openConfirm = (confirmData: ConfirmProps): Promise<boolean> => {
  return new Promise((resolve) => {
    const callback: ConfirmCallback = (isConfirmed) => {
      return resolve(isConfirmed);
    };

    useConfirmStore().openConfirm({ ...confirmData, callback });
  });
};
