<template>
  <Modal :open="alertData.isOpen" disablePortal :hide-close-icon="true" size="l" max-width="514px">
    <template #default>
      <div :class="styles.alert">
        <img src="./assets/container.png" alt="Picture" :class="styles.picture" />

        <div :class="styles.wrapper">
          <div :class="styles.content">
            <Typography variant="h1">
              {{ displayedTitle }}
            </Typography>

            <Typography v-if="displayedDescription" as="span" variant="body-2">
              {{ displayedDescription }}
            </Typography>
          </div>

          <BaseButton type="primary" size="l" variant="text-s-medi" @click="onCloseAlert">
            <template #left-icon>
              <ChevronLeftIcon />
            </template>

            Вернуться назад
          </BaseButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { useAlertStore } from './model';
import styles from './styles.module.css';
import ChevronLeftIcon from '../../assets/icons/chevron-left.svg';
import { BaseButton } from '../base-button';
import { Modal } from '../modal';
import { Typography } from '../typography';

export type AlertType = '400' | '429' | '500';

const { alertData, onCloseAlert } = useAlertStore();

const errors = {
  400: {
    title: 'Данные введены неверно',
    description: 'Проверьте правильность введенных данных',
  },
  429: {
    title: 'Слишком много запросов, попробуйте позже',
    description: '',
  },
  500: {
    title: 'Что-то пошло не так',
    description:
      'На сервере произошла непредвиденная ошибка. Пожалуйста, подождите, она вскоре будет исправленна',
  },
};

const displayedTitle = computed(() => errors[alertData.type].title);
const displayedDescription = computed(() => errors[alertData.type].description);
</script>
