<template>
  <i :style="spinnerStyle" :class="styles.spinner" />
</template>

<script setup lang="ts">
import { computed } from 'vue';

import styles from './styles.module.css';

export interface SpinnerProps {
  size?: number | string;
  color?: 'primary' | 'light' | 'danger';
}

const props = withDefaults(defineProps<SpinnerProps>(), { size: '20px', color: 'primary' });

const spinnerStyle = computed(() => {
  let colorStyle = {};
  if (props.color === 'primary') {
    colorStyle = {
      '--spinner-color': '#17B26A',
      '--spinner-track-color': '#1ad07b',
    };
  }

  if (props.color === 'light') {
    colorStyle = {
      '--spinner-color': '#FFFFFF',
      '--spinner-track-color': '#FFFFFF99',
    };
  }

  if (props.color === 'danger') {
    colorStyle = {
      '--spinner-color': '#F04438',
      '--spinner-track-color': '#f14c41',
    };
  }

  return {
    ...colorStyle,
    '--spinner-size': isNaN(props.size as number) ? props.size : `${props.size}px`,
  };
});
</script>
