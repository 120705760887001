<template>
  <Modal
    :title="confirmStore.confirmData?.title"
    :description="confirmStore.confirmData?.description"
    :open="confirmStore.isOpen"
    :hide-close-icon="confirmStore.confirmData?.hideCloseIcon"
    :max-width="confirmStore.confirmData?.maxWidth"
    :z-index="confirmStore.confirmData?.zIndex"
    size="l"
    type="confirm"
    @update:open="onClose"
  >
    <template #actions v-if="confirmStore.confirmData">
      <BaseButton
        v-if="!confirmStore?.confirmData.cancelButtonOnly"
        full
        type="primary"
        size="m"
        @click="onConfirm()"
      >
        {{ confirmStore.confirmData?.confirmButtonText || 'Подтвердить' }}
      </BaseButton>

      <BaseButton
        v-if="!confirmStore?.confirmData.confirmButtonOnly"
        full
        type="secondary"
        size="m"
        @click="onClose(false)"
      >
        {{ confirmStore.confirmData?.cancelButtonText || 'Отмена' }}
      </BaseButton>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useConfirmStore } from './model';
import { BaseButton } from '../base-button';
import { Modal } from '../modal';

const confirmStore = useConfirmStore();

const onConfirm = (): void => {
  confirmStore.onClose(true);
};

const onClose = (value: boolean): void => {
  if (!value) {
    confirmStore.onClose(false);
  }
};
</script>
