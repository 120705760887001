<template>
  <ToastRoot v-bind="forwarded" :class="styles.toast">
    <div :class="styles.texts">
      <ToastTitle as-child>
        <typography variant="text-body">
          {{ title }}
        </typography>
      </ToastTitle>

      <ToastDescription v-if="description">
        <typography variant="text-body">
          {{ description }}
        </typography>
      </ToastDescription>
    </div>

    <ToastClose :class="styles.close">
      <CloseIcon />
    </ToastClose>
  </ToastRoot>
</template>

<script setup lang="ts">
import { reactiveOmit } from '@vueuse/core';
import type { ToastRootProps, ToastRootEmits } from 'radix-vue';
import {
  ToastClose,
  ToastDescription,
  ToastRoot,
  ToastTitle,
  useForwardPropsEmits,
} from 'radix-vue';

import type { ToastProps } from './model';
import styles from './styles.module.css';
import CloseIcon from '../../assets/icons/close.svg';

const props = defineProps<ToastProps & ToastRootProps>();
const toastRootProps = reactiveOmit(props, 'title', 'description');
const emits = defineEmits<ToastRootEmits>();

const forwarded = useForwardPropsEmits(toastRootProps, emits);
</script>
