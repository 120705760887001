import { defineStore } from 'pinia';
import { reactive } from 'vue';

import type { AlertType } from './index.vue';

const DEFAULT_ALERT_TYPE = '400';

export const useAlertStore = defineStore('alert', () => {
  const alertData = reactive<{ type: AlertType; isOpen: boolean }>({
    isOpen: false,
    type: DEFAULT_ALERT_TYPE,
  });

  const onOpenAlert = (alertType: AlertType): void => {
    alertData.type = alertType;
    alertData.isOpen = true;
  };

  const onCloseAlert = (): void => {
    alertData.isOpen = false;
  };

  return {
    alertData,
    onOpenAlert,
    onCloseAlert,
  };
});

export const openAlert = (alertStatus: number): void => {
  if (alertStatus === 500) {
    useAlertStore().onOpenAlert('500');
  } else if (alertStatus === 429) {
    useAlertStore().onOpenAlert('429');
  } else if (alertStatus && alertStatus >= 400 && alertStatus < 500) {
    useAlertStore().onOpenAlert('400');
  }
};
