import type { LngLat } from '@yandex/ymaps3-types';

export const API_HOST = import.meta.env.VITE_API_HOST;
export const YANDEX_MAP_API_KEY = import.meta.env.VITE_YANDEX_MAP_API_KEY;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const DEFAULT_COORDS: LngLat = [
  +import.meta.env.VITE_DEFAULT_LNG,
  +import.meta.env.VITE_DEFAULT_LAT,
];

export const TEXT_REGEX = /^[0-9\s!"'(),\-./:;_А-Яа-яA-Za-z]+$/;
export const NAME_REGEX = /^[a-zA-Zа-яА-Я-\s]+$/;

export const BASE_MESSAGE = 'Введите данные';
export const BASE_MESSAGE_MATCH = 'Данные введены неверно';
export const BASE_MESSAGE_MAX_LENGTH = 'Максимальное количество символов -';
