import fontClasses from './fonts.module.css';
import weightClasses from './weights.module.css';

export const weightMap = {
  '100': weightClasses.thin,
  '300': weightClasses.light,
  '400': weightClasses.regular,
  '500': weightClasses.medium,
  '600': weightClasses.semiBold,
  '700': weightClasses.bold,
};

export type TypographyWeight = keyof typeof weightMap;

export const variantsMap = {
  h1: fontClasses.h1,
  h2: fontClasses.h2,
  h3: fontClasses.h3,

  'h1-semi': fontClasses.h1Semi,
  'h2-semi': fontClasses.h2Semi,
  'h3-semi': fontClasses.h3Semi,

  'footnote-reg': fontClasses.footnoteReg,
  'footnote-semi': fontClasses.footnoteSemi,

  'display-s': fontClasses.displayS,
  'display-l': fontClasses.displayL,

  'headline-semi': fontClasses.headlineSemi,
  'subheadline-semi': fontClasses.subheadlineSemi,

  'text-xs': fontClasses.textXs,
  'text-xs-medi': fontClasses.textXsMedi,
  'text-xs-semi': fontClasses.textXsSemi,
  'text-s-medi': fontClasses.textSMedi,
  'text-m': fontClasses.textM,
  'text-xl': fontClasses.textXl,

  'caption-reg': fontClasses.captionReg,
  'caption-bold': fontClasses.captionBold,

  'body-1': fontClasses.body1,
  'body-2': fontClasses.body2,
  'body-3': fontClasses.body3,

  'error-title': fontClasses.errorTitle,
};

export type TypographyVariant = keyof typeof variantsMap;

type TextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';
export const elementMaps: Record<TypographyVariant, TextElement> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',

  'h1-semi': 'h1',
  'h2-semi': 'h2',
  'h3-semi': 'h3',

  'footnote-reg': 'p',
  'footnote-semi': 'p',

  'display-s': 'span',
  'display-l': 'span',

  'headline-semi': 'span',
  'subheadline-semi': 'span',

  'text-xs': 'span',
  'text-xs-medi': 'span',
  'text-xs-semi': 'span',
  'text-s-medi': 'span',
  'text-m': 'span',
  'text-xl': 'span',

  'caption-reg': 'span',
  'caption-bold': 'span',

  'body-1': 'span',
  'body-2': 'span',
  'body-3': 'span',

  'error-title': 'h1',
};

export interface TypographyProps {
  variant: TypographyVariant;
  as?: string;
  weight?: TypographyWeight;
}
