<template>
  <DialogRoot v-bind="forwarded">
    <DialogPortal :disabled="disablePortal">
      <DialogOverlay
        :class="[styles.overlay, { [styles.video]: isVideo }]"
        :style="{ zIndex: zIndex }"
      >
        <DialogClose
          v-if="!hideCloseIcon && isVideo"
          :class="[styles.close, styles.video]"
          @click="closeModal"
        >
          <CloseIcon />
        </DialogClose>

        <DialogContent
          @pointer-down-outside="onClickOutside"
          :class="[styles.content, styles[size], styles[type], { [styles.video]: isVideo }]"
          :style="{ maxWidth: maxWidth }"
        >
          <div :class="styles.header" v-if="headerTitle || !hideCloseIcon || $slots.icon">
            <div v-if="headerTitle" :class="styles.headerBlock">
              <Typography :variant="headerTitleVariant">
                {{ headerTitle }}
              </Typography>

              <Typography variant="body-2">
                {{ headerDescription }}
              </Typography>
            </div>

            <div v-if="$slots.icon" :class="styles.icon">
              <slot name="icon" />
            </div>

            <DialogClose
              v-if="!hideCloseIcon && !isVideo"
              :class="styles.close"
              @click="closeModal"
            >
              <CloseIcon />
            </DialogClose>
          </div>

          <DialogTitle v-if="$slots.title || title" as-child>
            <slot name="title">
              <Typography :class="styles.text" as="div" variant="h3-semi">{{ title }}</Typography>
            </slot>
          </DialogTitle>

          <DialogDescription v-if="$slots.description || description" as-child>
            <slot name="description">
              <Typography :class="styles.text" as="div" variant="body-2">
                {{ description }}
              </Typography>
            </slot>
          </DialogDescription>

          <slot :close="closeModal" />

          <div v-if="$slots.actions" :class="[styles.actions, styles[type]]">
            <slot :close="closeModal" name="actions" />
          </div>
        </DialogContent>
      </DialogOverlay>
    </DialogPortal>
  </DialogRoot>
</template>

<script setup lang="ts">
import { reactiveOmit } from '@vueuse/core';
import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogDescription,
  useForwardPropsEmits,
} from 'radix-vue';
import type { DialogRootEmits, DialogRootProps } from 'radix-vue';

import styles from './styles.module.css';
import CloseIcon from '../../assets/icons/close.svg';
import { Typography, type TypographyVariant } from '../typography';

export interface ModalProps extends DialogRootProps {
  title?: string;
  description?: string;
  /** By default, Modal renders inside the <body>, but this property can disable
   * the behaviour and render the Modal right where it is called. */
  disablePortal?: boolean;
  hideCloseIcon?: boolean;
  headerTitle?: string;
  headerDescription?: string;
  headerTitleVariant?: TypographyVariant;
  maxWidth?: string;
  closeOnClickOutside?: boolean;
  size?: 's' | 'm' | 'l';
  type?: 'default' | 'confirm';
  zIndex?: number;
  isVideo?: boolean;
}

const props = withDefaults(defineProps<ModalProps>(), {
  hideCloseIcon: false,
  disablePortal: false,
  closeOnClickOutside: true,
  size: 's',
  type: 'default',
  headerTitle: '',
  headerDescription: '',
  headerTitleVariant: 'h2',
  isVideo: false,
});
const emit = defineEmits<DialogRootEmits>();

const dialogRootProps = reactiveOmit(
  props,
  'disablePortal',
  'title',
  'description',
  'size',
  'type',
  'headerTitle',
  'maxWidth',
  'hideCloseIcon',
  'closeOnClickOutside',
);
const forwarded = useForwardPropsEmits(dialogRootProps, emit);

defineSlots<{
  default(props: { close: VoidFunction }): never;
  title(props: object): never;
  description(props: object): never;
  actions(props: { close: VoidFunction }): never;
  icon(props: object): never;
}>();

const closeModal = (): void => {
  emit('update:open', false);
};

const onClickOutside = (event: Event): void => {
  if (!props.closeOnClickOutside) {
    event.preventDefault();
  }
};
</script>
