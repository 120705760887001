<template>
  <button
    :type="submit ? 'submit' : 'button'"
    :disabled="isDisabled"
    :class="[
      styles.button,
      styles[size],
      styles[type],
      {
        [styles['full']]: full,
        [styles.rounded]: props.rounded,
        [styles.iconOnly]: $slots['left-icon'] && !$slots['default'] && !$slots['right-icon'],
      },
    ]"
  >
    <Spinner v-if="loading" :size="spinnerSize" :color="spinnerColor" />

    <template v-else-if="empty">
      <slot />
    </template>

    <template v-else>
      <span v-if="$slots['left-icon']" :class="iconStyles">
        <slot name="left-icon" />
      </span>

      <Typography v-if="$slots['default']" :variant="typographyVariant" :class="styles.text">
        <slot />
      </Typography>

      <span v-if="$slots['right-icon']" :class="iconStyles">
        <slot name="right-icon" />
      </span>
    </template>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import styles from './styles.module.css';
import { Spinner } from '../spinner';
import { Typography, type TypographyVariant } from '../typography';

export interface BaseButtonProps {
  submit?: boolean;
  type?:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'danger'
    | 'danger-outline'
    | 'secondary-border-none';
  size?: 'xs' | 's' | 'm' | 'l';
  disabled?: boolean;
  loading?: boolean;
  full?: boolean;
  empty?: boolean;
  variant?: TypographyVariant | null;
  rounded?: boolean;
}

const props = withDefaults(defineProps<BaseButtonProps>(), {
  type: 'primary',
  size: 'm',
  disabled: false,
  loading: false,
  full: false,
  empty: false,
  variant: null,
  rounded: false,
});

defineSlots<{
  default(props: object): never;
  'left-icon'(props: object): never;
  'right-icon'(props: object): never;
}>();

const isDisabled = computed(() => props.disabled || props.loading);

const sizeToTypographyMap: Record<Required<BaseButtonProps>['size'], TypographyVariant> = {
  xs: props.variant ?? 'footnote-semi',
  s: props.variant ?? 'subheadline-semi',
  m: props.variant ?? 'headline-semi',
  l: props.variant ?? 'headline-semi',
};
const typographyVariant = computed<TypographyVariant>(() => sizeToTypographyMap[props.size]);

const sizeToSpinnerSizeMap: Record<Required<BaseButtonProps>['size'], number> = {
  xs: 12,
  s: 12,
  m: 16,
  l: 18,
};
const spinnerSize = computed<number>(() => sizeToSpinnerSizeMap[props.size]);

const spinnerColor = computed((): 'primary' | 'light' | 'danger' => {
  if (props.type === 'primary') {
    return 'primary';
  }

  if (props.type === 'danger') {
    return 'danger';
  }

  return 'light';
});

const iconStyles = computed<string[]>(() => {
  const classes = [styles.icon, styles[props.type]];

  if (isDisabled.value) {
    classes.push(styles.disabled);
  }

  return classes;
});
</script>
