import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createYmaps } from 'vue-yandex-maps';

import { YANDEX_MAP_API_KEY } from 'shared/constants';
import { useSentry } from 'shared/integrations/sentry';
import { Typography } from 'shared/ui';

import App from './app.vue';
import router from './router';

import 'modern-normalize/modern-normalize.css';
import 'unfonts.css';
import './main.css';

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

useSentry(app, router);
app.use(pinia);
app.use(router);
app.use(
  createYmaps({
    apikey: YANDEX_MAP_API_KEY,
  }),
);
app.component('typography', Typography);

app.mount('#app');
